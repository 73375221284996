import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from '../components/sidebar/Sidebar'
import { format } from 'date-fns'
import { IoMdMail } from "react-icons/io";
import { FaUserFriends, FaBed, FaBath } from 'react-icons/fa';
import { MdBedroomChild } from "react-icons/md";
import { IoIosCheckmarkCircle, IoMdCloseCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { PiClockCountdownFill } from "react-icons/pi";
import { toast } from 'react-toastify';

const GHOSTDATA = () => {
    const user = useSelector(state => state.user)
    const model = useSelector(state => state.modelType)
    const token = useSelector(state => state.token)

    const [open, setOpen] = useState(false)
    const [commission, setCommission] = useState(0)

    // const token = useSelector(state => state.token)
    const location = useLocation();
    const navigate = useNavigate()
    const data = location.state?.data
    console.log("data - ", data)

    const kroHandle = async (email) => {
        //email , commisionPercentage
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/approve-commission-type-hosts`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    email: data?.email,
                    commissionPercentage: commission
                })
            })
            const res_data = await res.json()
            if (res.ok) {
                console.log(res_data)
                toast.success("Host Model Changed Successfully")
            }

        } catch (err) {
            console.log(err)
        }

}
useEffect(() => {
    if (!data || data.length === 0) {
        navigate('/admin/all-hosts');
        return;
    }
}, [data, navigate])


return (
    <>
        {user && model === 'admin'
            ? (
                <>
                    <SideBar />
                    <div className='h-full md:pl-[70px] lg:pl-[260px] w-full bg-slate-100 flex flex-col justify-center items-center py-12'>


                        <div className={`w-full px-20 flex flex-col justify-between items-center gap-4`}>
                            {/* //-----------------avatar, name, email, del------------------ */}
                            <div className={`bg-white rounded  w-full flex justify-between items-center`}>
                                <div className="w-full  flex items-center px-4 py-2 gap-2">
                                    {/* //---------avatar---------------- */}
                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${data?.avatar.replace("public", "")}`} alt="avatar" className="w-20 h-20 rounded-full object-cover  " />
                                    {/* //---------name, email----- ----------- */}
                                    <div className='w-full p-2 flex flex-col justify-center'>
                                        <h1 className="text-lg font-bold">{data?.firstName} {data?.lastName}</h1>
                                        <div className="text-xs text-black/70 font-semibold pb-0.5">Host ID : {data?._id}</div>
                                        <div className='font-semibold text-slate-700 flex items-center gap-1 '>
                                            <IoMdMail />
                                            {data?.email}</div>
                                    </div>

                                </div>
                                <div className="w-full  flex justify-end items-center pr-4">
                                    <span className="font-semibold text-slate-700 px-1">
                                        Model :
                                    </span>
                                    <span className="font-semibold text-slate-700 pr-3">
                                        {data?.interestType ? data?.interestType : "N/A"}
                                    </span>
                                    <span>
                                        <button className="bg-rose-500 hover:bg-rose-600 cursor-pointer text-white px-3 py-1 rounded-md font-semibold" onClick={() => setOpen(!open)}>Change</button>
                                    </span>

                                </div>

                            </div>
                            <div className={`bg-white py-5 px-6 rounded  w-full flex flex-col`}>
                                <div className='font-semibold pb-2 border-b '>PERSONAL DETAILS</div>
                                <div className='w-full grid grid-cols-3 pt-2'>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>First Name :</div>
                                        <div className='font-medium text-lg'>{data?.firstName}</div>
                                    </div>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>Last Name :</div>
                                        <div className='font-medium text-lg'>{data?.lastName}</div>
                                    </div>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>Registered on :</div>
                                        <div className='font-medium text-lg'>{format(new Date(data?.createdAt), 'MMMM dd, yyyy HH:mm')}</div>
                                    </div>
                                </div>
                                <div className='w-full grid grid-cols-3 '>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>Email ID:</div>
                                        <div className='font-medium text-lg'>{data?.email ? data?.email : "N/A"}</div>
                                    </div>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>Email ID Verified :</div>
                                        <div className='font-medium text-lg'>{data?.emailVerified ? "Yes" : "No"}</div>
                                    </div>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>Phone No. :</div>
                                        <div className='font-medium text-lg'>{data?.phoneNumber ? data?.phoneNumber : "N/A"}</div>
                                    </div>
                                </div>
                                <div className='w-full grid grid-cols-3'>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>Languages :</div>
                                        <div className='font-medium text-lg'>{data?.languages ? data?.languages : "N/A"}</div>
                                    </div>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>Work :</div>
                                        <div className='font-medium text-lg'>{data?.work ? data?.work : "N/A"}</div>
                                    </div>
                                    <div className='w-full p-2'>
                                        <div className='font-semibold text-sm text-gray-500'>Time Zone :</div>
                                        <div className='font-medium text-lg'>{data?.timeZone ? data?.timeZone : "N/A"}</div>
                                    </div>
                                </div>
                                <div className='w-full grid grid-cols-3 pb-2'>
                                    <div className='w-full p-2 col-span-3'>
                                        <div className='font-semibold text-sm text-gray-500'>About :</div>
                                        <div className='font-medium text-lg'>{data?.about ? data?.about : "N/A"}</div>
                                    </div>
                                </div>

                            </div>
                            <div className={`bg-white py-5 px-8 rounded  w-full flex flex-col`}>
                                <div className='font-semibold pb-2 border-b mb-2 '>PROPERTY DETAILS</div>
                                <div className='font-semibold w-full text-end text-sm  mb-2 '>Total Properties : {data?.properties?.length}</div>
                                <div className='gap-2 flex flex-col'>
                                    {data?.properties?.length > 0
                                        ? data?.properties?.map((property, index) => (
                                            <div key={index} className='w-full hover:border-slate-800 rounded border flex items-start p-2 duration-500 cursor-pointer' onClick={() => { navigate(`/properties/${property?._id}`) }}>
                                                <div className=''>
                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${property?.listingPhotoPaths[0]?.replace("public", "")}`} className='rounded w-[230px] h-[120px]' />
                                                </div>
                                                <div className='w-full px-3'>
                                                    <div className='flex gap-2 items-center font-semibold text-lg text-slate-800'>{property?.title} <span>  {
                                                        property?.adminStatus === "Approved" ? <IoIosCheckmarkCircle className={`text-lg text-green-400 bg-white rounded-full p-0`} />
                                                            : property?.adminStatus === "Rejected" ? <IoMdCloseCircleOutline className={`text-lg text-red-400 bg-white rounded-full p-0`} />
                                                                : <PiClockCountdownFill className={`text-lg text-white bg-black/20 drop-shadow-lg rounded-full p-0`} />

                                                    }</span> </div>
                                                    <div className='font-semibold text-slate-500 text-sm'>{property?.city}, {property?.province}, {property?.country}</div>
                                                    <div className='font-semibold text-slate-700 py-2 text-lg'>{property?.price} USD <span className={`text-black/50 text-base`}>/ Night</span></div>
                                                    <div className='w-full flex gap-2 text-sm text-slate-700'>
                                                        <div className='flex items-center gap-1'>
                                                            <FaUserFriends className='text-sm' />
                                                            <span>{property?.guestCount} Guests</span>
                                                        </div>
                                                        <div className='flex items-center gap-1'>
                                                            <MdBedroomChild />
                                                            <span>{property?.bedroomCount} Bedrooms</span>
                                                        </div>
                                                        <div className='flex items-center gap-1'>
                                                            <FaBed />
                                                            <span>{property?.bedCount} Beds</span>
                                                        </div>
                                                        <div className='flex items-center gap-1'>
                                                            <FaBath />
                                                            <span>{property?.bathroomCount} Bathrooms</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                        : <div className='w-full text-center text-xl font-semibold p-2'>No Properties yet!</div>
                                    }
                                </div>

                            </div>
                        </div>

                        {open &&
                            <div className="fixed  md:pl-[70px] lg:pl-[260px] top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center">
                                <div className="bg-white p-5 rounded-md w-80">
                                    <div className="flex justify-between items-center pb-2 border-b mb-2">
                                        <h1 className="text-lg text-slate-700 font-semibold">Change Host Model</h1>
                                        <span onClick={() => setOpen(!open)} className="text-slate-700 hover:text-rose-500 font-semibold cursor-pointer"><IoClose /></span>
                                    </div>
                                    <div className="">
                                        <label htmlFor="email" className="mt-2 text-slate-500 text-sm  font-semibold">Host Email</label>
                                        <input type="text" name="email" value={data?.email} placeholder="Enter Email" className="w-full p-2 border rounded-md outline-none" />

                                        <label htmlFor="commission" className="text-sm text-slate-500 mt-3 font-semibold">Commission %</label>
                                        <input name="commission" type="number" placeholder="Enter Commission Percentage"
                                            value={commission}
                                            onChange={(e) => setCommission(e.target.value)}
                                            className="outline-none w-full p-2 border rounded-md " />
                                        <div className="text-xs py-1 text-slate-500">Note: This will change the host's model to Commission</div>
                                        <div className="flex justify-end items-center gap-2">
                                            <div onClick={()=>{kroHandle(data?.email); setOpen(!open)}} className="outline-none bg-slate-700 hover:bg-slate-800 text-white font-semibold px-3 py-1 rounded mt-4 cursor-pointer">Change</div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </>

            )
            : <></>
        }
    </>

)
}

export default GHOSTDATA