import React, { useEffect, useState , useCallback} from 'react';
import './StatsTable.css'; // Import CSS file for styling
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { MdPersonAddAlt1 } from "react-icons/md";

const StatsTable = () => {
  // const [data, setData] = useState([]);  // State to hold fetched data
  // const [loading, setLoading] = useState(true);  // State to manage loading state
  const token = useSelector((state) => state.token);
  const [agents , setAgents] = useState([])

  const getAllAgents = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/all-agents`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log('All Agents:', data.data);
      setAgents(data.data);
      // setData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [token]);

  useEffect(() => {
    getAllAgents();
  }, [getAllAgents]);

  // useEffect(() => {
  //   // Simulate fetching data from an external source (e.g., an API)
  //   // const mockData = [
  //   //   {
  //   //     agent: 'John Doe',
  //   //     status: 'Online',
  //   //     attempted: 100,
  //   //     noOfOffers: 15,
  //   //     loginTime: '08:00 AM',
  //   //     awayTime: '00:30',
  //   //     logoutTime: '04:00 PM',
  //   //     calls1To3Min: 30,
  //   //     calls3To5Min: 25,
  //   //     calls5To7Min: 20,
  //   //     calls7To10Min: 10,
  //   //     calls10To20Min: 5,
  //   //     calls20PlusMin: 3,
  //   //     totalTalkTime: '4h 30m',
  //   //     noOfSales: 8,
  //   //     expectedSales: 10,
  //   //     totalAmount: '$1200'
  //   //   },
  //   //   {
  //   //     agent: 'Jane Smith',
  //   //     status: 'Offline',
  //   //     attempted: 80,
  //   //     noOfOffers: 10,
  //   //     loginTime: '09:00 AM',
  //   //     awayTime: '01:00',
  //   //     logoutTime: '03:00 PM',
  //   //     calls1To3Min: 20,
  //   //     calls3To5Min: 18,
  //   //     calls5To7Min: 15,
  //   //     calls7To10Min: 5,
  //   //     calls10To20Min: 2,
  //   //     calls20PlusMin: 1,
  //   //     totalTalkTime: '3h 15m',
  //   //     noOfSales: 5,
  //   //     expectedSales: 7,
  //   //     totalAmount: '$800'
  //   //   },
  //   //   {
  //   //     agent: 'Jane Smith',
  //   //     status: 'Away',
  //   //     attempted: 80,
  //   //     noOfOffers: 10,
  //   //     loginTime: '09:00 AM',
  //   //     awayTime: '01:00',
  //   //     logoutTime: '03:00 PM',
  //   //     calls1To3Min: 20,
  //   //     calls3To5Min: 18,
  //   //     calls5To7Min: 15,
  //   //     calls7To10Min: 5,
  //   //     calls10To20Min: 2,
  //   //     calls20PlusMin: 1,
  //   //     totalTalkTime: '3h 15m',
  //   //     noOfSales: 5,
  //   //     expectedSales: 7,
  //   //     totalAmount: '$800'
  //   //   },
  //   //   // Add more mock data entries as needed for testing
  //   // ];
  //   // Simulate delay to mimic API call
  //   setTimeout(() => {
  //     setData(mockData);  // Set fetched data to state
  //     // setLoading(false);  // Set loading to false once data is fetched
  //   }, 1000);
  // }, []);
  // const handleSearchClick = () => {
  //   navigate('/admin/dashboard/manuallyedit'); // Navigate to the 'results' page
  // };


  return (
    <div className="stats-table-container">
      <h2 className='stats-table-title'>Agent Details</h2>
      <div className="mb-1.5 w-full p-2 flex justify-end items-center">
      {/* <button className=' !w-72  py-2 px-4 rounded-md bg-slate-700 hover:bg-slate-800  !font-semibold !text-white !tracking-wide text-lg !flex items-center justify-center gap-1' onClick={handleSearchClick}>Manually Edit</button> */}
      <Link to='/admin/register-agent' className=' !w-72  py-2 px-4 rounded-md bg-slate-700 hover:bg-slate-800  !font-semibold !text-white !tracking-wide text-lg !flex items-center justify-center gap-x-4' > <MdPersonAddAlt1/> Create Agent</Link>
      </div>
      {/* {loading ? (
        <p>Loading data...</p>  // Display loading message while fetching data
      ) : ( */}
      <div className="w-full text-end font-semibold text-slate-700 text-sm tracking-wide pt-3">
        Total Agents : {agents.length}
      </div>
        <table className="stats-table">
          <thead>
            <tr>
              <th>Agents</th>
              <th>Email</th>
              <th>Phone </th>
              {/* <th>Attempted</th> */}
              <th>No of Offers</th>
              <th>Created On</th>
              {/* <th>Away Time</th> */}
              {/* <th>Last Logout Time</th>
              <th>1-3 min calls</th>
              <th>3-5 min calls</th>
              <th>5-7 min calls</th>
              <th>7-10 min calls</th>
              <th>10-20 min calls</th>
              <th>20+ min calls</th>
              <th>Total Talk Time</th>
              <th>No of Sales</th>
              <th>Expected Sales</th>
              <th>Total Amount</th> */}
            </tr>
          </thead>
          <tbody className='cursor-arrow'>
            {agents?.map((agent, index) => (  // Dynamically render table rows based on fetched data
              <tr key={index}>
                <td>{agent?.firstName + " " + agent?.lastName}</td>
                <td>{agent?.email}</td>
                <td>{agent?.phoneNumber}</td>
                <td>{agent?.offersCount}</td>
                <td>{format(new Date(agent?.createdAt), "dd-MM-yyyy , HH:mm")}</td>
                {/* <td>{row.status}</td> */}
                {/* <td>
                    <div className="dt-status">
                      <span
                        className={`dt-status-dot dot-${row.status}`}
                      ></span>
                      <span className="dt-status-text">{row.status}</span>
                    </div>
                  </td>
                <td>{row.attempted}</td>
                <td>{row.noOfOffers}</td>
                <td>{row.loginTime}</td>
                <td>{row.awayTime}</td>
                <td>{row.logoutTime}</td>
                <td>{row.calls1To3Min}</td>
                <td>{row.calls3To5Min}</td>
                <td>{row.calls5To7Min}</td>
                <td>{row.calls7To10Min}</td>
                <td>{row.calls10To20Min}</td>
                <td>{row.calls20PlusMin}</td>
                <td>{row.totalTalkTime}</td>
                <td>{row.noOfSales}</td>
                <td>{row.expectedSales}</td>
                <td>{row.totalAmount}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      {/* )} */}
    </div>
  );
};

export default StatsTable;
